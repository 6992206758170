import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import { createPersistedState } from "pinia-plugin-persistedstate";

import './assets/css/style.css';

const app = createApp(App);
app.use(router);

const pinia = createPinia();
pinia.use(createPersistedState());

app.use(pinia);
app.mount('#app');
