<script>
import { Common } from '@/api'
import { mapStores } from 'pinia'
import { useDispParamStore } from '@/stores/dispParam'

import Modal from "@/components/basics/SimpleModal.vue";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      opExchangePoint: '',  //99999999,
      pontaAwardPoint: '',  //99999999,
      opPoint: '',  //99999999.99,
      pontaPoint: '',  //99999999,

      modalMessage: "よろしいですか？",
      modal: false,

      COMPLETE_MSG: '　　　　交換が完了しました　　　　',
      yesCaption: 'OK',

      form: {
        EncGid: '',
        EncryptedPontaId: '',
        OpExchangePoint: ''
      },
      processing: false
    }
  },
  computed: {
    ...mapStores(useDispParamStore),
  },
  methods: {
    async goRegister () {
      if (this.processing) return;

      this.processing = true;
      this.form.OpExchangePoint = this.opExchangePoint;

      const result = await Common.exchangeExec(this.form);
      // console.log('result:', result);
      this.processing = false;

      // console.debug('result:', result);
      switch (result.ResultCode) {
        case '0':
          break;
        case '401':
          this.modalMessage = '交換ポイント数が保有ポイントを超えています';
          this.showModal();
          return;
        case '402':
          this.modalMessage = '交換ポイント数がPontaに付与可能なポイント数を超えています';
          this.showModal();
          return;
        case '703':
          this.modalMessage = '交換ポイント数の指定が上限を超えているか正しくありません';
          this.showModal();
          return;
          // this.gotoMembership;
        case '900':
          this.modalMessage = 'システムエラーが発生しました';
          this.showModal();
          return;
        default:
          this.modalMessage = 'エラーが発生しました(' + result.ResultCode + ')';
          this.showModal();
          return;
      }

      const dialog = document.getElementById('jsi-dialog');
      dialog.showModal();

    },
    gotoCancel() {
      this.$router.push('/op/ob/fe/exchange')
    },
    async setLabel () {
      // console.log('exchangeConfirm-myStore.opExchangePoint', this.dispParamStore.opExchangePoint);

      this.form.EncGid = this.dispParamStore.encGid;
      this.form.EncryptedPontaId = this.dispParamStore.encryptedPontaId;
      this.opExchangePoint = this.dispParamStore.opExchangePoint;
      this.pontaAwardPoint = this.dispParamStore.pontaAwardPoint;
      this.opPoint = this.dispParamStore.opPoint;
      this.pontaPoint = this.dispParamStore.pontaPoint;

      if (!this.form.EncGid) {
        this.$router.push('/login');
      }
      if (!this.opExchangePoint) {    // 会員メニューからブラウザバックの時
        this.$router.push('/login');
      }

    },
    showModal() {
      // モーダル表示する際の処理が必要ならここに書く
      this.modal = true;
    },
    executeMethod(yes) {
      // モーダルを非表示にして、モーダルでの選択結果によって処理を変える
      this.modal = false;
      if (yes) {
        if (this.modalMessage === this.COMPLETE_MSG) {
          this.$router.push('/op/ob/fe/menu');
          return;
        }
        // alert("はい が押されました。");
      } else {
        // alert("いいえ が押されました。");
      }
    },
    getCommaFormatNum(num) {
      if (num === '') {
        return num;
      }
      if (!isNaN(num)) {
        return Number(num).toLocaleString();
      }
      else {
        return num;
      }
    },
    exchangeComplete() {
      const dialog = document.getElementById('jsi-dialog');
      dialog.close();
      this.$router.push('/op/ob/fe/menu');
    }
  },
  created: async function () {
    await this.setLabel()
  },
}
</script>

<template>
	<div id="contents" class="check_exchange">
		<dialog class="dialog" id="jsi-dialog">
			<div class="dialog_inner">
				<h2 class="dialog_ttl">交換が完了しました。</h2>
				<div class="dialog_pic"><img src="../assets/img/ponta_03.png" alt="Ponta"></div>
				<div class="btn_wrap">
          <button type="button" class="btn btn-back" @click="exchangeComplete">OPEN POINT会員証へ戻る</button>
				</div>
			</div>
		</dialog>
		<div class="inner">
			<h2 class="page-ttl">交換ポイント数の確認</h2>
			<div class="wh-block">
				<ul class="check-list ly-block">
					<li class="check-item check-model">
						<h2 class="check-ttl">Pontaポイントに交換します。<br>よろしいですか？</h2>
						<ul class="check-item_inner change-model">
							<li class="change-model_item change-model_item-open">
								<div class="app-icon"><img src="../assets/img/logo_openpoint_app.png" alt="OPENPOINT"></div>
								<div class="point">
									<div class="point_icon"><img src="../assets/img/icon_p_openpoint01.png" alt="Point"></div>
									<p class="point_num ly-txt-open" id="jsi-pointNum">{{ getCommaFormatNum(this.opExchangePoint) }}</p>
								</div>
							</li>
							<li class="change-model_item change-model_item-ponta">
								<div class="app-icon"><img src="../assets/img/logo_ponta_app.png" alt="Ponta"></div>
								<div class="point">
									<div class="point_icon"><img src="../assets/img/icon_p_ponta01.png" alt="Point"></div>
									<p class="point_num ly-txt-ponta" id="jsi-pointNum">{{ getCommaFormatNum(this.pontaAwardPoint) }}</p>
								</div>
							</li>
						</ul>
					</li>
					<li class="check-item have-point">
						<h2 class="check-ttl">交換後の保有ポイント</h2>
						<div class="check-item_inner">
							<dl>
								<dt>OPEN POINT</dt>
								<dd>
									<div class="point">
										<div class="point_icon"><img src="../assets/img/icon_p_openpoint01.png" alt="Point"></div>
										<p class="point_num" id="jsi-pointNum">{{ getCommaFormatNum(this.opPoint) }}</p>
									</div>
								</dd>
							</dl>
							<dl>
								<dt>Pontaポイント</dt>
								<dd>
									<div class="point">
										<div class="point_icon"><img src="../assets/img/icon_p_ponta01.png" alt="Point"></div>
										<p class="point_num" id="jsi-pointNum">{{ getCommaFormatNum(this.pontaPoint) }}</p>
									</div>
								</dd>
							</dl>
						</div>
						<div class="btn_wrap">
							<button type="button" id="jsi-modalOpen" v-bind:class="{ buttoncolor: processing, btn: !processing }" :disabled="processing" @click="goRegister">交換する</button>
              <router-link class="btn btn-cancel" to="/op/ob/fe/exchange">キャンセル</router-link>
						</div>
					</li>
				</ul>
				<div class="ponta_pic"><img src="../assets/img/ponta_02.png" alt="ponta"></div>
			</div>
		</div>
	</div>

  <div class="modal">
    <modal
      :message="modalMessage"
      :yesCaption="yesCaption"
      v-show="modal"
      @execute-method="executeMethod"
    ></modal>
  </div>
</template>

<style>
.buttoncolor {
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	border-radius: 5px;
	width: 100%;
	min-height: 50px;
  background-color: lightgray;
	position: relative;
	margin: 15px auto;
}
</style>