import axios from 'axios'
import router from '@/router'

const userApi = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  responseType: 'json'
})

userApi.interceptors.request.use((config) => {
  // config.headers = { authorization: store.state.auth.token }
  // store.dispatch('isLoading', true)
  return config
})

userApi.interceptors.response.use(
  (response) => {
    // store.dispatch('isLoading', false)
// console.log('response.data:', response.data);
    const ResultCode = response.data.ResultCode;
    switch (ResultCode) {
      case '702': // EncGid=null
        router.push({ path: '/login' })
        return;
      case '405': // EncryptedPontaId=null
        router.push({ path: '/login' })
        return;
    }

    return response.data
  },
  (error) => {
    // store.dispatch('isLoading', false)
    if (error.response.status === 401 || error.response.status === 403) {
      // store.dispatch('authClear', {})
      router.push({ name: 'login', params: error.response.data })
    }
    if (error.response.status === 500) {
      router.push({ path: '/error' })
      return Promise.reject(error.response.data)
    }
    if (error.response.status === 503) {
      router.push({ path: '/maintenance' })
      return Promise.reject(error.response.data)
    }
    if (error.response.messages === undefined) {
      error.response.messages = []
    }
    return Promise.reject(error.response.data)
  })

export default userApi
