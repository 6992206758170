<template>
  <div id="modal">
    <div id="modal-content" class="modal">
    <!-- <div id="jsi-dialog" class="dialog"> -->
  <!-- <dialog class="dialog" id="jsi-dialog"> -->
    <div class="dialog_inner">
      <h2 class="dialog_ttl">{{ message }}</h2>
      <div class="dialog_pic"><img src="@/assets/img/ponta_03.png" alt="Ponta"></div>
      <div class="btn_wrap">
        <button type="button" class="btn btn-back" @click="returnTrue()">{{ yesCaption }}</button>
      </div>
    </div>
  <!-- </dialog> -->
  </div>
  </div>

  <!-- <div id="modal">
    <div id="modal-content" class="modal">
      <p id="modal-message" class="modal__message">{{ message }}</p>
      <button class="modal__btn" @click="returnTrue()">{{ yesCaption }}</button>
    </div>
    <div id="modal-overlay"></div>
  </div> -->
</template>

<script>
export default {
  name: "Modal",
  props: {
    message: {
      type: String
    },
    yesCaption: {
      type: String,
      default: "OK",
    },
  },
  methods: {
    returnFalse() {
      this.$emit("execute-method", false);
    },
    returnTrue() {
      this.$emit("execute-method", true);
    },
  },
};
</script>

<style scoped>
.modal {
  padding: 10px 20px;
  border: 2px solid #a5272a;
  background: #faebd7;
  z-index: 2;
  display: block;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.modal__message {
  margin-top: 10px;
}

.modal__cancel {
  margin-right: 30px;
  font-size: 15px;
}

.modal__cancel:hover {
  cursor: pointer;
  color: rgb(14, 48, 240);
  font-weight: bold;
}

.modal__btn {
  display: inline-block;
  margin: 30px auto;
  text-decoration: none;
  width: 90%;
  height: 30px;
  text-decoration: none;
  color: #000000;
  border: solid 2px #a5272a;
  border-radius: 3px;
  transition: 0.4s;
  text-align: center;
  vertical-align: middle;
  font-size: 15px;
  background-color: #faebd7;
}

.modal__btn:hover {
  background: #a5272a;
  color: white;
  cursor: pointer;
}

#modal-overlay {
  z-index: 1;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.75);
}
</style>