import { createRouter, createWebHistory } from 'vue-router'
import Entry from './views/Entry.vue'
import Membership from './views/Membership.vue'
import Menu from './views/Menu.vue'
import Barcode from './views/Barcode.vue'
import Exchange from './views/Exchange.vue'
import ExchangeConfirm from './views/ExchangeConfirm.vue'

const LoginPage = process.env.VUE_APP_LOGIN_PAGE; //'https://st.ponta.jp/front/LWAS900/SLWAS900110.htm?a=520e08a1793bb6836a7b2379b0520642&p=%2Fop%2Fob%2Ffe%2Fentry&q=param1%3Dtest1%26param2%3Dtest2';

const routes = [
  { path: '/op/ob/fe/entry', name: 'Entry', component: Entry },
  { path: '/op/ob/fe/membership', name: 'Membership', component: Membership },
  { path: '/op/ob/fe/menu', name: 'Menu', component: Menu },
  { path: '/op/ob/fe/barcode', name: 'Barcode', component: Barcode },
  { path: '/op/ob/fe/exchange', name: 'Exchange', component: Exchange },
  { path: '/op/ob/fe/exchange_confirm', name: 'ExchangeConfirm', component: ExchangeConfirm },
  { path: '/login', name: 'Login', beforeEnter() {
      window.location = LoginPage
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router