<script>
import { Common } from '@/api'

export default {
  name: "Modal",
  props: {
    message: {
      type: String
    },
    yesCaption: {
      type: String,
      default: "OK",
    },
  },
  data() {
    return {
      termsHtml: '',
      modalMessage: "よろしいですか？",
      modal: false,

      form: {
        EncryptedPontaId: '',
        TermsVersion: '',  //'1'
      }
    }
  },
  methods: {
    async setLabel () {
    },
    async loadHtml() {
      const result = await Common.termsInit({});
      // console.log('terms_init-html:', result.html.substring(0, 10));
      this.termsHtml = result.html;
    },
  },
  created: async function () {
    await this.setLabel();
  },
  mounted: async function () {
    this.loadHtml();
  }
};
</script>

<template>
  <div id="new"></div>
    <p v-html="this.termsHtml"></p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
</template>
