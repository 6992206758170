import { defineStore } from 'pinia'

export const useDispParamStore = defineStore('dispParam', {
  state: () => {
    return {
      gid: '',
      encGid: '',
      encryptedPontaId: '',
      termsVersion: '',

      opExchangePoint: '',
      pontaAwardPoint: '',
      opPoint: '',
      pontaPoint: '',
    }
  },
  persist: true,
})