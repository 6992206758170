<script>
import { Common } from '@/api'
import { mapStores } from 'pinia'
import { useDispParamStore } from '@/stores/dispParam'

import Modal from "@/components/basics/SimpleModal.vue";

const LandingPage = process.env.VUE_APP_LANDING_PAGE;

export default {
  components: {
    Modal,
  },
  data() {
    return {
      W: '',
      modalMessage: "よろしいですか？",
      modal: false,
      form: {
        Gid: '',
      }
    }
  },
  computed: {
    ...mapStores(useDispParamStore),
  },
  methods: {
    gotoMenu() {
      this.$router.push('/op/ob/fe/menu')
    },
    gotoMembership() {
      this.$router.push('/op/ob/fe/membership')
    },
    showModal() {
      // モーダル表示する際の処理が必要ならここに書く
      this.modal = true;
    },
    executeMethod(yes) {
      // モーダルを非表示にして、モーダルでの選択結果によって処理を変える
      this.modal = false;
      if (yes) {
        // alert("はい が押されました。");
      } else {
        // alert("いいえ が押されました。");
      }
    },
    clearStore() {
      this.dispParamStore.$reset;

      this.dispParamStore.gid = '';
      this.dispParamStore.encryptedPontaId = '';
      this.dispParamStore.termsVersion = '';
      this.dispParamStore.opExchangePoint = '';
      this.dispParamStore.pontaAwardPoint = '';
      this.dispParamStore.opPoint = '';
      this.dispParamStore.pontaPoint = '';
    }
  },
  created: async function () {
    this.W = this.$route.query.w;
    if (!this.W) {
      window.location.href = LandingPage;
      return;
    }
  // console.log('w:', this.$route.query.w)

    const result = await Common.entry({ W: this.W });
  // console.log('entry-result2:', result)

    this.clearStore();

    switch (result.ResultCode) {
      case '0':
        this.dispParamStore.gid = result.Gid;
        this.dispParamStore.encGid = result.EncGid;
        this.dispParamStore.encryptedPontaId = result.EncryptedPontaId;
        // console.log('this.dispParamStore:' , this.dispParamStore);

        this.$router.push('/op/ob/fe/menu')
        // this.gotoMenu;
        break;
      case '301': //規約合意がまだ
        this.dispParamStore.encryptedPontaId = result.EncryptedPontaId;
        this.dispParamStore.termsVersion = result.TermsVersion + '';

  // console.log('Entry-aft-301:useMyStore:' , this.dispParamStore.encryptedPontaId);
        this.$router.push('/op/ob/fe/membership')
        break;
      case '900':
        window.location.href = LandingPage; // 遷移のループ回避のためlandingへ
        // this.modalMessage = 'システムエラーが発生しました';
        // this.showModal();
        return;
      default:
        this.modalMessage = 'エラーが発生しました(' + result.ResultCode + ')';
        this.showModal();
        return;
    }

    // await this.setLabel()
  },
  // mounted() {
  //   window.onload = ()=>{
  //     this.gotoMenu;
  //   }
  // }
}
</script>

<template>
  <div class="modal">
    <modal
      :message="modalMessage"
      v-show="modal"
      @execute-method="executeMethod"
    ></modal>
  </div>
</template>
