import client from './client'

const apiBasePath = '/op/ob/api/'

export default {
  entry: async form => {
    return client.post(apiBasePath + 'entry', form)
    // client.post(apiBasePath + 'menu_init', form)
  },
  termsInit: async form => {
    return client.post(apiBasePath + 'terms_init', form)
    // client.post(apiBasePath + 'menu_init', form)
  },
  termsAgreement: async form => {
    return client.post(apiBasePath + 'terms_agreement', form)
    // client.post(apiBasePath + 'menu_init', form)
  },
  menuInit: async form => {
    return client.post(apiBasePath + 'menu_init', form)
    // client.post(apiBasePath + 'menu_init', form)
  },
  getCouponParam: async form => {
    return client.post(apiBasePath + 'get_coupon_param', form)
  },
  getCouponParamProd: async form => {
    return client.post(apiBasePath + 'get_coupon_param_prod', form)
  },
  barcodeInit: async form => {
    return client.post(apiBasePath + 'barcode_init', form)
    // client.post(apiBasePath + 'menu_init', form)
  },
  barcodeUpdate: async form => {
    return client.post(apiBasePath + 'barcode_update', form)
    // client.post(apiBasePath + 'menu_init', form)
  },
  exchangeInit: async form => {
    return client.post(apiBasePath + 'exchange_init', form)
    // client.post(apiBasePath + 'menu_init', form)
  },
  exchangeCheck: async form => {
    return client.post(apiBasePath + 'exchange_check', form)
    // client.post(apiBasePath + 'menu_init', form)
  },
  exchangeExec: async form => {
    return client.post(apiBasePath + 'exchange_exec', form)
    // client.post(apiBasePath + 'menu_init', form)
  },
}
