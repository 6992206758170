<script>
import { Common } from '@/api'
import { mapStores } from 'pinia'
import { useDispParamStore } from '@/stores/dispParam'

import Modal from "@/components/basics/SimpleModal.vue";
import Terms from "@/components/Terms.vue";

export default {
  components: {
    Modal,
    Terms,
  },
  data() {
    return {
      modalMessage: "よろしいですか？",
      modal: false,

      form: {
        EncryptedPontaId: '',
        TermsVersion: '',
      },
      base_pc_css: process.env.VUE_APP_CSS_FQDN + '/c/cmn/css/base_pc.css',   //'https://st.ponta.jp/c/cmn/css/base_pc.css'
      style_css: process.env.VUE_APP_CSS_FQDN + '/c/ppm/css/style.css',       //'https://st.ponta.jp/c/ppm/css/style.css'
      landingPage: process.env.VUE_APP_LANDING_PAGE,
      processing: false
    }
  },
  computed: {
    ...mapStores(useDispParamStore),
  },
  methods: {
    async goRegister () {
      if (this.processing) return;

      this.processing = true;
      const result = await Common.termsAgreement(this.form);
      // console.log('result:', result);
      this.processing = false;
      switch (result.ResultCode) {
        case '0':
          break;
        case '900':
          this.modalMessage = 'システムエラーが発生しました';
          this.showModal();
          return;
        case '405': // PontaId=null or 誤り
        case '701': // 規約バージョンエラー
          this.$router.push('/login');
          return;
        default:
          this.modalMessage = 'エラーが発生しました(' + result.ResultCode + ')';
          this.showModal();
          return;
      }

      this.dispParamStore.gid = result.Gid;
      this.dispParamStore.encGid = result.EncGid;

      this.$router.push('/op/ob/fe/menu')
    },
    async setLabel () {
      this.form.EncryptedPontaId = this.dispParamStore.encryptedPontaId;
      this.form.TermsVersion = this.dispParamStore.termsVersion;
      if (!this.form.EncryptedPontaId || !this.form.TermsVersion) {
        this.$router.push('/login');
      }
    },
    showModal() {
      // モーダル表示する際の処理が必要ならここに書く
      this.modal = true;
    },
    executeMethod(yes) {
      // モーダルを非表示にして、モーダルでの選択結果によって処理を変える
      this.modal = false;
      if (yes) {
        // alert("はい が押されました。");
      } else {
        // alert("いいえ が押されました。");
      }
    },
  },
  created: async function () {
    await this.setLabel();
  },
}
</script>

<template>
  <link rel="stylesheet" type="text/css" :href="base_pc_css" media="all">
  <link rel="stylesheet" type="text/css" :href="style_css" media="all">
  <div class="container-wrap rsp">
    <div id="contents" class="privacy rule">
      <div class="inner">
        <div id="container">

          <div class="maincontents">

            <h1 class="maintitle">Ponta会員向けOPEN POINTサービス 利用規約</h1>
              <div id="new"></div>
              <Terms />
          <!-- /#main --></div>
        <!-- /#container --></div>

      </div>
    <!-- /#contents --></div>

    <!-- <div class="submit-btn_wrap"> -->
    <div class="btn_wrap">
      <ul class="btn_list">
        <li><input type="submit" value="同意する" v-bind:class="{ buttoncolor: processing, btn: !processing }" :disabled="processing" id="jsi-agreeBtn" @click="goRegister"></li>
        <li><a :href="landingPage" class="btn_cancel">同意しない</a></li>
      </ul>
    </div>
  <!-- /#wrap --></div>

  <div class="modal">
    <modal
      :message="modalMessage"
      v-show="modal"
      @execute-method="executeMethod"
    ></modal>
  </div>
</template>

<style>
#footer {
		padding-bottom: 110px;
}
.container-wrap .btn_wrap {
  display: block;
  position: fixed;
  right:0;
  left:0;
  bottom:0;
  padding: 15px 0;
  font-size: 14px;
  background-color: rgba(255,255,255,0.9);
  z-index: 100;
  margin: auto;
}
.container-wrap .btn_list {
  width: calc(100% - 30px);
  margin: 0 auto;
  text-align: center;
}

.container-wrap .btn_list li + li {
  margin-top: 15px;
}

.container-wrap .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  background-color: #e50012;
  border-radius: 8px;
  width: 100%;
  max-width:400px;
  height: 50px;
  margin: auto;
}

.container-wrap .btn_cancel {
  color: #09c;
  font-weight: bold;
  text-decoration: underline;
}

.buttoncolor {
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	border-radius: 5px;
	width: 100%;
	min-height: 50px;
  background-color: lightgray;
	position: relative;
	margin: 15px auto;
}
</style>
