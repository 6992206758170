<script>
import { Common } from '@/api'
import { mapStores } from 'pinia'
import { useDispParamStore } from '@/stores/dispParam'

import VueBarcode from '@chenfengyuan/vue-barcode';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { DateTime, Duration } from 'luxon';
import Modal from "@/components/basics/SimpleModal.vue";

export default {
  components: {
    "vue-barcode": VueBarcode,
    VueQrcode,
    Modal,
  },
  data() {
    return {
      dynacodeTimeLimit: 60 * 5,
      // dynacodeTimeLimit: 15,
      opPoint: '',
      dynacode: '',
      gid: '',
      now: DateTime.local().set({ milliseconds: 0 }),
      end: DateTime.local().set({ milliseconds: 0 }).plus({ seconds: this.dynacodeTimeLimit }),
      tick: null,
      modalMessage: "よろしいですか？",
      modal: false,
      form: {
        EncGid: '',
        EncryptedPontaId: ''
      }
    }
  },
  watch: {
    async now() {
      // タイムアップ後の処理
      if (this.finished) {
        this.gotoRecount();
      }
    }
  },
  computed: {
    ...mapStores(useDispParamStore),
    remaining() {
      return this.end.diff(this.now).toObject()
    },
    display() {
      return Duration.fromObject(this.remaining).toFormat('mm:ss')
    },
    finished() {
      return this.now >= this.end
    }
  },
  mounted() {
    this.tick = setInterval(() => {
      this.now = DateTime.local().set({ milliseconds: 0 })
    }, 1000)
  },
  methods: {
    gotoMenu() {
      this.$router.push('/op/ob/fe/menu')
    },
    async gotoRecount() {
      this.now = DateTime.local().set({ milliseconds: 0 });
      this.end = DateTime.local().set({ milliseconds: 0 }).plus({ seconds: this.dynacodeTimeLimit });

      const result = await Common.barcodeUpdate(this.form);
      // console.log('barcodeUpdate(recount):', result);
      switch (result.ResultCode) {
        case '0':
          break;
        case '900':
          this.dynacode = '';
          clearInterval(this.tick); // timer停止
          this.modalMessage = 'システムエラーが発生しました';
          this.showModal();
          return;
        default:
          this.dynacode = '';
          clearInterval(this.tick); // timer停止
          this.modalMessage = 'エラーが発生しました(' + result.ResultCode + ')';
          this.showModal();
          return;
      }

      this.dynacode = result.OpDynacode;

      this.now = DateTime.local().set({ milliseconds: 0 });
      this.end = DateTime.local().set({ milliseconds: 0 }).plus({ seconds: this.dynacodeTimeLimit });
      this.tick = null;
    },
    async setLabel () {
      this.form.EncGid = this.dispParamStore.encGid;
      this.form.EncryptedPontaId = this.dispParamStore.encryptedPontaId;
      if (!this.form.EncGid) {
        this.$router.push('/login');

      }

      // システムエラー時0:00から始まるのを防ぐため
      this.now = DateTime.local().set({ milliseconds: 0 });
      this.end = DateTime.local().set({ milliseconds: 0 }).plus({ seconds: this.dynacodeTimeLimit });

      const result = await Common.barcodeInit(this.form);
      // console.log('barcodeInit:', result);
      switch (result.ResultCode) {
        case '0':
          break;
        case '900':
          this.dynacode = '';
          clearInterval(this.tick); // timer停止
          this.modalMessage = 'システムエラーが発生しました';
          this.showModal();
          return;
        default:
          this.dynacode = '';
          clearInterval(this.tick); // timer停止
          this.modalMessage = 'エラーが発生しました(' + result.ResultCode + ')';
          this.showModal();
          return;
      }
      this.dynacode = result.OpDynacode;
      this.opPoint = result.OpPoint;
      // console.log('this.dynacode:', this.dynacode);
      this.now = DateTime.local().set({ milliseconds: 0 });
      this.end = DateTime.local().set({ milliseconds: 0 }).plus({ seconds: this.dynacodeTimeLimit });

    },
    showModal() {
      // モーダル表示する際の処理が必要ならここに書く
      this.modal = true;
    },
    executeMethod(yes) {
      // モーダルを非表示にして、モーダルでの選択結果によって処理を変える
      this.modal = false;
      if (yes) {
        // alert("はい が押されました。");
      } else {
        // alert("いいえ が押されました。");
      }
    },
    getCommaFormatNum(num) {
      if (num === '') {
        return num;
      }
      if (!isNaN(num)) {
        return Number(num).toLocaleString();
      }
      else {
        return num;
      }
    }
  },
  created: async function () {
    await this.setLabel()
  },
}
</script>

<template>
	<div id="contents" class="use">
		<div class="inner ly-block">
			<h2 class="page-ttl">台湾セブンイレブンで<br>OPEN POINTをつかう</h2>
			<div class="main-box">
				<div class="main-box_line">
					<h3 class="logo"><img src="../assets/img/logo_openpoint.png" alt="OPENPOINT"></h3>
					<div class="point">
						<div class="point_icon"><img src="../assets/img/icon_p_openpoint01.png" alt="Point"></div>
						<p class="point_num" id="jsi-pointNum">{{ getCommaFormatNum(this.opPoint) }}</p>
					</div>
					<div class="barcode_dynacode">
            <div class="barcode_pic" id="jsi-barcodePic">
              <vue-barcode v-if="dynacode" :value="dynacode" tag="img" :options="{ format: 'CODE128B', displayValue: false, width: 1.97, height: 120, margin: 0}"></vue-barcode>
            </div>
						<p class="barcode_num" id="jsi-barcodeNum">{{ this.dynacode }}</p>
					</div>
					<div class="squarecode">
						<div class="squarecode_pic" id="jsi-squarecodePic">
              <VueQrcode v-if="dynacode" :value="dynacode" tag="img" :options="{ width: 144, margin: 0 }" />
            </div>
						<div class="squarecode_time">
							<div id="jsi-codeTimeCount" class="squarecode_count">{{ display }}</div>
							<button type="button" class="squarecode_reload" id="jsi-timeReload" @click="gotoRecount"><img src="../assets/img/icon_reload.png" alt="更新する"></button>
						</div>
					</div>
					<div class="ponta_pic"><img src="../assets/img/ponta_01.png" alt="ponta"></div>
				</div>
				<p class="note-txt">※バーコードを読み取る際は、端末の設定から画面の明るさを最大限にしてください。</p>
			</div>
			<p class="page-txt">OPEN POINTのご利用は保有するOPEN POINT全てとなります。<br/>OPEN POINTは統一超商の店舗で一般商品の消費金額（タバコ、DHL、代理販売商品を除く）及び水道代、電気代、ガス代、駐車料金、ナンバープレート税、地価税、家屋税及び手数料、所得税、営業税、保険料、有線テレビ料金の収納代行金額に使用できます。</p>
			<div class="btn_wrap">
        <router-link class="btn btn-back" to="/op/ob/fe/menu">戻る</router-link>
			</div>
		</div>
	</div>

  <div class="modal">
    <modal
      :message="modalMessage"
      v-show="modal"
      @execute-method="executeMethod"
    ></modal>
  </div>
</template>

<style>
</style>