<script>
import { Common } from '@/api'
import { mapStores } from 'pinia'
import { useDispParamStore } from '@/stores/dispParam'

import Modal from "@/components/basics/SimpleModal.vue";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      gid: '',
      pontaPoint: '',
      opPoint: '',  //99,
      pontaExchangeRate: '',
      exchangeNum: '',
      modalMessage: "よろしいですか？",
      modal: false,
      form: {
        EncGid: '',
        EncryptedPontaId: '',
        OpExchangePoint: ''
      }
    }
  },
  watch: {
    exchangeNum: function(newVal) {
      this.str = newVal.length > 8 ? newVal.slice(0, -1) : newVal;
    }
  },
  computed: {
    ...mapStores(useDispParamStore),
    noValue() {
      if (this.exchangeNum && Number(this.exchangeNum) > 0) {
        return false
      }
      return true
    }
  },
  methods: {
    gotoCancel() {
      this.$router.push('/op/ob/fe/menu')
    },
    async gotoExchangeConfirm() {
      this.form.OpExchangePoint = this.exchangeNum;

      // console.debug('data:', this.data);
      // console.debug('form:', this.form);

      const result = await Common.exchangeCheck(this.form);

      // console.debug('result:', result);
      switch (result.ResultCode) {
        case '0':
          break;
        case '401':
          this.modalMessage = '交換ポイント数が保有ポイントを超えています';
          this.showModal();
          return;
          // this.gotoMembership;
        case '402':
          this.modalMessage = '交換ポイント数がPontaに付与可能なポイント数を超えています';
          this.showModal();
          return;
          // this.gotoMembership;
        case '703':
          this.modalMessage = '交換ポイント数の指定が上限を超えているか正しくありません';
          this.showModal();
          return;
          // this.gotoMembership;
        case '900':
          this.modalMessage = 'システムエラーが発生しました';
          this.showModal();
          return;
        default:
          this.modalMessage = 'エラーが発生しました(' + result.ResultCode + ')';
          this.showModal();
          return;
      }

      this.dispParamStore.opExchangePoint = result.OpExchangePoint;
      this.dispParamStore.pontaAwardPoint = result.PontaAwardPoint;
      this.dispParamStore.opPoint = result.OpPoint;
      this.dispParamStore.pontaPoint = result.PontaPoint;

      // console.log('exchange-myStore.opExchangePoint', this.dispParamStore.opExchangePoint);

      this.$router.push('/op/ob/fe/exchange_confirm')
    },
    async setLabel () {
      this.form.EncGid = this.dispParamStore.encGid;
      this.form.EncryptedPontaId = this.dispParamStore.encryptedPontaId;
      if (!this.form.EncGid) {
        this.$router.push('/login');
      }

      const result = await Common.exchangeInit(this.form);
      // console.log('result:', result);
      switch (result.ResultCode) {
        case '0':
          break;
        case '900':
          this.modalMessage = 'システムエラーが発生しました';
          this.showModal();
          return;
        default:
          this.modalMessage = 'エラーが発生しました(' + result.ResultCode + ')';
          this.showModal();
          return;
      }
      this.pontaExchangeRate = result.OpPontaExcRate;
      this.opPoint = result.OpPoint;
      this.pontaPoint = result.PontaPoint;
    },
    showModal() {
      // モーダル表示する際の処理が必要ならここに書く
      this.modal = true;
    },
    executeMethod(yes) {
      // モーダルを非表示にして、モーダルでの選択結果によって処理を変える
      this.modal = false;
      if (yes) {
        // alert("はい が押されました。");
      } else {
        // alert("いいえ が押されました。");
      }
    },
    exchangeNumBlur() {
      // const numberInput = this.$refs.numberInput.value;
      // if (numberInput !== this.exchangeNum) {   // 1.00入力⇒1に変換の対応
      //   this.exchangeNum = numberInput;
      // }
      this.exchangeNum = this.$refs.numberInput.value;  // 1.00入力⇒1に変換の対応
      this.exchangeNum = Math.trunc(this.exchangeNum);
      // this.exchangeNum = Math.trunc(Number(this.$refs.numberInput.value));
      if(this.exchangeNum < 0) this.exchangeNum = 0;
      if(this.exchangeNum > Number(this.opPoint)) this.exchangeNum = Math.trunc(this.opPoint)
    },
    getCommaFormatNum(num) {
      if (num === '') {
        return num;
      }
      if (!isNaN(num)) {
        return Number(num).toLocaleString();
      }
      else {
        return num;
      }
    }
  },
  created: async function () {
    await this.setLabel()
  },
}
</script>

<template>
	<div id="contents" class="exchange">
		<div class="inner">
			<h2 class="page-ttl">Pontaポイントへ交換する</h2>
			<div class="wh-block">
				<ul class="exchange-list ly-block">
					<li class="exchange-item">
						<ul class="exchange-item_inner change-model">
							<li class="change-model_item change-model_item-open">
								<div class="app-icon"><img src="../assets/img/logo_openpoint_app.png" alt="OPENPOINT"></div>
								<div class="point">
									<div class="point_icon"><img src="../assets/img/icon_p_openpoint01.png" alt="Point"></div>
									<p class="point_num ly-txt-open">1</p>
								</div>
							</li>
							<li class="change-model_item change-model_item-ponta">
								<div class="app-icon"><img src="../assets/img/logo_ponta_app.png" alt="Ponta"></div>
								<div class="point">
									<div class="point_icon"><img src="../assets/img/icon_p_ponta01.png" alt="Point"></div>
									<p class="point_num ly-txt-ponta">{{ getCommaFormatNum(this.pontaExchangeRate) }}</p>
								</div>
							</li>
						</ul>
					</li>
					<li class="check-item have-point">
						<h2 class="check-ttl">現在の保有ポイント</h2>
						<div class="check-item_inner">
							<dl>
								<dt>OPEN POINT</dt>
								<dd>
									<div class="point">
										<div class="point_icon"><img src="../assets/img/icon_p_openpoint01.png" alt="Point"></div>
										<p class="point_num" id="jsi-pointNum">{{ getCommaFormatNum(this.opPoint) }}</p>
									</div>
								</dd>
							</dl>
							<dl>
								<dt>Pontaポイント</dt>
								<dd>
									<div class="point">
										<div class="point_icon"><img src="../assets/img/icon_p_ponta01.png" alt="Point"></div>
										<p class="point_num" id="jsi-pointNum">{{ getCommaFormatNum(this.pontaPoint) }}</p>
									</div>
								</dd>
							</dl>
						</div>
					</li>
					<li class="exchange-item exchange-input">
							<dl class="exchange-item_inner">
								<dt>交換するポイント数</dt>
								<dd>
									<input type="number" v-model.trim="exchangeNum" ref="numberInput"
                  oninput="javascript:if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  @blur="exchangeNumBlur"
                   id="exchangeNum" name="exchangeNum" min="1" max="999999" maxlength="6" placeholder="0" required class="input-numbox">
								</dd>
							</dl>
							<div class="submit-btn_wrap">
								<input type="submit" value="入力内容を確認する" class="submit-btn" id="jsi-confirmBtn" @click="gotoExchangeConfirm" v-bind:disabled="noValue">
							</div>
              <div class="btn_wrap">
                <router-link class="btn btn-back" to="/op/ob/fe/menu">OPEN POINT会員証へ戻る</router-link>
              </div>
          </li>
				</ul>
				<div class="ponta_pic"><img src="../assets/img/ponta_02.png" alt="ponta"></div>
			</div>

			<div class="att wh-block">
				<ul class="att_list ly-block">
					<li class="att_item">
						<dl class="att_inner">
							<dt class="att_ttl">ポイント交換レート</dt>
							<dd>
								<p>OPEN POINT 1P  →  Pontaポイント {{ getCommaFormatNum(this.pontaExchangeRate) }}P</p>
							</dd>
						</dl>
					</li>
					<li class="att_item">
						<dl class="att_inner">
							<dt class="att_ttl">ポイント交換の単位</dt>
							<dd>
								<p>1Pから交換可能となります。</p>
							</dd>
						</dl>
					</li>
					<li class="att_item">
						<dl class="att_inner">
							<dt class="att_ttl">お手続きから交換までの期間</dt>
							<dd>
								<p>即時反映</p>
							</dd>
						</dl>
					</li>
					<li class="att_item">
						<dl class="att_inner">
							<dt class="att_ttl">対象</dt>
							<dd>
								<p>Ponta会員IDをお持ちで、OPEN POINT会員サービス利用規約に同意したお客様</p>
							</dd>
						</dl>
					</li>
					<li class="att_item">
						<dl class="att_inner">
							<dt class="att_ttl">お問い合わせ先</dt>
							<dd>
								<p>カード裏面記載のコールセンター</p>
							</dd>
						</dl>
					</li>
				</ul>
			</div>
		</div>
	</div>

  <div class="modal">
    <modal
      :message="modalMessage"
      v-show="modal"
      @execute-method="executeMethod"
    ></modal>
  </div>
</template>
